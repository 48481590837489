import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  IonContent,
  IonItem,
  IonLabel,
  IonInput,
  IonTextarea,
  IonButton,
  IonIcon,
  IonCheckbox,
  IonGrid,
  IonRow,
  IonCol,
  IonToast,
  IonCard,
  IonCardHeader,
  IonLoading,
  IonImg,
  IonTitle,
} from '@ionic/react';

import { api, idCompany } from '../services/api';
import { logoWhatsapp } from 'ionicons/icons';
import done from '../theme/images/done.gif';

export default function Modal({ cart, total }) {
  const dispatch = useDispatch();
  const addressReducer = useSelector((state) => state.address);

  const [takeAway, setTakeAway] = useState(false);
  const [card, setCard] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [greetings, setGreetings] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [name, setName] = useState(addressReducer.name);
  const [phone, setPhone] = useState(addressReducer.phone);
  const [address, setAddress] = useState(addressReducer.address);
  const [note, setNote] = useState('');
  const [message, setMessage] = useState('');

  const sendPurchase = async (data) => {
    try {
      setShowLoading(true);
      const purchase = await api.post('/purchase', data);
      if (purchase.data.error) {
        setMessage(purchase.data.error);
        setShowLoading(false);
        setShowToast(true);
      } else {
        window.open(purchase.data.purchase_message);
        setShowLoading(false);
        setGreetings(true);
      }
    } catch (error) {
      setMessage('Erro - Puxe para Atualizar');
      setShowLoading(false);
      setShowToast(true);
    }
  };

  const buy = () => {
    if (!name) {
      setMessage('* Preencha os itens obrigatórios *');
      setShowToast(true);
    } else if (!phone) {
      setMessage('Telefone deve conter apenas números');
      setShowToast(true);
    } else if (!takeAway && !address) {
      setMessage('* Preencha os itens obrigatórios *');
      setShowToast(true);
    } else {
      var data = {
        id_company: idCompany,
        deal: { takeAway, card, total },
        buyer: { name, phone, address, note },
        cart: cart,
      };
      dispatch({
        type: '@address/SAVE',
        payload: { name, phone, address },
      });
      sendPurchase(data);
    }
  };

  return (
    <IonContent>
      {greetings ? (
        <>
          <br />
          <IonImg src={done} />
          <br />
          <IonTitle style={{ textAlign: 'center' }}>
            <big>Muito Obrigado!</big>
          </IonTitle>
        </>
      ) : (
        <>
          <IonCard>
            <IonCardHeader>FINALIZAR PEDIDO</IonCardHeader>
            <IonItem lines='inset'>
              <IonLabel position='floating'>Nome:*</IonLabel>
              <IonInput
                value={name}
                onIonChange={(e) => setName(e.detail.value)}
              ></IonInput>
            </IonItem>
            <IonItem lines='inset'>
              <IonLabel position='floating'>Telefone:*</IonLabel>
              <IonInput
                value={phone}
                type='number'
                maxlength='11'
                onIonChange={(e) => setPhone(e.detail.value)}
              ></IonInput>
            </IonItem>
            <IonItem lines='inset'>
              <IonLabel position='floating'>Endereço:*</IonLabel>
              <IonInput
                value={address}
                disabled={takeAway}
                onIonChange={(e) => setAddress(e.detail.value)}
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel position='stacked'>Observações:</IonLabel>
              <IonTextarea
                placeholder='Troco, Modificações, Horários etc...'
                onIonChange={(e) => setNote(e.detail.value)}
              ></IonTextarea>
            </IonItem>
          </IonCard>
          <IonCard>
            <IonGrid>
              <IonRow className='justify-content'>
                <IonItem lines='none'>
                  <IonCol>
                    <IonLabel>Retirar na Loja</IonLabel>
                  </IonCol>
                  <IonCol>
                    <IonCheckbox
                      mode='ios'
                      color='success'
                      checked={takeAway}
                      onIonChange={(e) => setTakeAway(e.detail.checked)}
                    />
                  </IonCol>{' '}
                  <IonCol>
                    <IonLabel>Entregar</IonLabel>
                  </IonCol>
                  <IonCol>
                    <IonCheckbox
                      mode='ios'
                      color='success'
                      checked={!takeAway}
                      onIonChange={(e) => setTakeAway(!e.detail.checked)}
                    />
                  </IonCol>
                </IonItem>
              </IonRow>
              {takeAway ? (
                ''
              ) : (
                <IonRow className='justify-content'>
                  <IonItem lines='none'>
                    <IonCol>
                      <IonLabel>Cartão</IonLabel>
                    </IonCol>
                    <IonCol>
                      <IonCheckbox
                        mode='ios'
                        color='success'
                        checked={card}
                        onIonChange={(e) => setCard(e.detail.checked)}
                      />
                    </IonCol>{' '}
                    <IonCol>
                      <IonLabel>Dinheiro</IonLabel>
                    </IonCol>
                    <IonCol>
                      <IonCheckbox
                        mode='ios'
                        color='success'
                        checked={!card}
                        onIonChange={(e) => setCard(!e.detail.checked)}
                      />
                    </IonCol>
                  </IonItem>
                </IonRow>
              )}
              <IonButton onClick={() => buy()} color='success' expand='block'>
                <IonLabel>Pedir pelo WhatsApp </IonLabel>
                <IonIcon icon={logoWhatsapp} />
              </IonButton>
            </IonGrid>
          </IonCard>
        </>
      )}
      <IonLoading
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        message={'Aguarde...'}
      />
      <IonToast
        mode='ios'
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={message}
        color='warning'
        position='top'
        duration={1000}
      />
    </IonContent>
  );
}
