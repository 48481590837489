export default function ProductReducer(state = [], action) {
  switch (action.type) {
    case '@product/DATA':
      const clone = JSON.parse(JSON.stringify(action.payload));
      return (state = clone);
    case '@product/INCREMENT_PRODUCT':
      return {
        ...state,
        quantity: state.quantity + 1,
        total: (state.quantity + 1) * state.price,
      };
    case '@product/DECREMENT_PRODUCT':
      if (state.quantity > 1) {
        return {
          ...state,
          quantity: state.quantity - 1,
          total: (state.quantity - 1) * state.price,
        };
      }
      return state;
    case '@product/INCREMENT_EXTRA':
      for (let index = 0; index < state.extra.length; index++) {
        if (state.extra[index].id === action.payload.extra) {
          state.extra[index].quantity++;
        }
      }
      let extraIncrease =
        state.total > 0
          ? state.extra.reduce(
              (previousCount, element) =>
                previousCount + element.price * element.quantity,
              0
            )
          : 0;
      return { ...state, total_extra: extraIncrease };
    case '@product/DECREMENT_EXTRA':
      for (let index = 0; index < state.extra.length; index++) {
        if (
          state.extra[index].id === action.payload.extra &&
          state.extra[index].quantity > 0
        ) {
          state.extra[index].quantity--;
        }
      }
      let extraDecrease =
        state.total > 0
          ? state.extra.reduce(
              (previousCount, element) =>
                previousCount + element.price * element.quantity,
              0
            )
          : 0;
      return { ...state, total_extra: extraDecrease };
    default:
      return state;
  }
}
