export const slideOptionsMobile = {
  speed: 1000,
  autoplay: {
    delay: 3000,
  },
  on: {
    beforeInit() {
      const swiper = this;
      swiper.classNames.push(`${swiper.params.containerModifierClass}fade`);
      const overwriteParams = {
        slidesPerView: 1.6,
        slidesPerColumn: 1,
        slidesPerGroup: 1,
        watchSlidesProgress: false,
        spaceBetween: 0,
        virtualTranslate: false,
      };
      swiper.params = Object.assign(swiper.params, overwriteParams);
      swiper.params = Object.assign(swiper.originalParams, overwriteParams);
    },
  },
};

export const slideOptionsPc = {
  speed: 1000,
  autoplay: {
    delay: 3000,
  },
  on: {
    beforeInit() {
      const swiper = this;
      swiper.classNames.push(`${swiper.params.containerModifierClass}fade`);
      const overwriteParams = {
        slidesPerView: 3,
        slidesPerColumn: 1,
        slidesPerGroup: 1,
        watchSlidesProgress: false,
        spaceBetween: 5,
        virtualTranslate: false,
      };
      swiper.params = Object.assign(swiper.params, overwriteParams);
      swiper.params = Object.assign(swiper.originalParams, overwriteParams);
    },
  },
};
