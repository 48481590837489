import React, { useState, useEffect } from 'react';
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCardContent,
  IonText,
  IonToast,
  IonRefresher,
  IonRefresherContent,
  IonChip,
} from '@ionic/react';

import { api, imgURL } from '../../services/api';
import AboutShimmer from '../../components/AboutShimmer';

function About() {
  const [showToast, setShowToast] = useState(false);
  const [data, setdata] = useState([]);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState('');

  useEffect(() => {
    setLoading(true);
    getInfo();
  }, []);

  const getInfo = async () => {
    try {
      const info = await api.get(`/company-info`);
      if (info.data.error) {
        setMessage(info.data.error);
        setShowToast(true);
      } else {
        setdata(info.data);
        setLoading(false);
      }
    } catch (err) {
      setMessage('Erro - Puxe para Atualizar');
      setShowToast(true);
    }
  };

  const doRefresh = (event) => {
    setLoading(true);
    getInfo();
    event.detail.complete();
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color='light'>
          <IonTitle className='text-align-center'>SOBRE</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonRefresher slot='fixed' onIonRefresh={doRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        {!loading ? (
          <>
            <IonCard
              onClick={() =>
                data.company.link && window.open(`http://${data.company.link}`)
              }
            >
              {data.company.logo && (
                <img
                  src={`${imgURL}storage/images/${data.company.logo}`}
                  alt='logo'
                />
              )}
              <IonCardHeader>
                <IonCardTitle>{data.company.name}</IonCardTitle>
                <IonCardSubtitle>{data.company.email}</IonCardSubtitle>
              </IonCardHeader>

              <IonCardContent>
                <IonText>{data.company.info}</IonText>
                <br />
                <IonText>{data.company.operation}</IonText>
                <br />
                <IonText>
                  Tel: (
                  {data.company.phone.slice(0, 2) +
                    ')' +
                    data.company.phone.slice(2, 6) +
                    '-' +
                    data.company.phone.slice(6, 11)}
                </IonText>
              </IonCardContent>
            </IonCard>
            {data.partners.length > 0 && (
              <IonChip
                style={{
                  textAlign: 'center',
                  display: 'block',
                }}
                outline
              >
                PARCEIROS
              </IonChip>
            )}
            {data.partners.map((partner, index) => (
              <IonCard
                key={index}
                onClick={() =>
                  partner.link && window.open(`http://${partner.link}`)
                }
              >
                <IonCardHeader>
                  <img
                    className='img-radious'
                    src={`${imgURL}storage/images/${partner.image}`}
                    alt=''
                  />
                  <IonCardTitle>{partner.title}</IonCardTitle>
                </IonCardHeader>
                <IonCardContent>
                  <IonText>{partner.description}</IonText>
                </IonCardContent>
              </IonCard>
            ))}
            <IonChip
              style={{
                textAlign: 'center',
                display: 'block',
              }}
              color='danger'
              outline
              onClick={() => window.open('http://web7app.com')}
            >
              Desenvolvido por Web7App.com
            </IonChip>
          </>
        ) : (
          <AboutShimmer />
        )}
      </IonContent>
      <IonToast
        mode='ios'
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={message}
        duration={5000}
        color='warning'
        position='top'
      />
    </IonPage>
  );
}

export default About;
