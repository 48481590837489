import React from 'react';

export default function ProductShimmer() {
  return (
    <>
      <div className='ProuctShimmer animate'></div>
      <div className='ProuctShimmer animate'></div>
      <div className='ProuctShimmer animate'></div>
      <div className='ProuctShimmer animate'></div>
      <div className='ProuctShimmer animate'></div>
      <div className='ProuctShimmer animate'></div>
      <div className='ProuctShimmer animate'></div>
      <div className='ProuctShimmer animate'></div>
      <div className='ProuctShimmer animate'></div>
      <div className='ProuctShimmer animate'></div>
    </>
  );
}
