import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonLoading,
  IonModal,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonRow,
  IonSearchbar,
  IonTitle,
  IonToast,
  IonToolbar,
} from '@ionic/react';

import { api } from '../../services/api';
import Product from '../../components/Product';
import { closeSharp } from 'ionicons/icons';
import ProductModal from '../../components/ProductModal';

import { Capacitor, Plugins } from '@capacitor/core';

export default function Search({ match }) {
  const cart = useSelector((state) => state.cart);
  const [products, setProducts] = useState([]);
  const [page, setPage] = useState();
  const [total, setTotal] = useState();
  const [showModal, setShowModal] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [message, setMessage] = useState('');
  const [keyword, setKeyword] = useState(match.params.search);
  const [lastPage, setLastPage] = useState('');
  const [showLoading, setShowLoading] = useState(true);
  const [item, setItem] = useState('');

  const getSearch = useCallback(async (key) => {
    if (key.length <= 2) {
      setMessage('Use no mínimo 3 caracteres');
      setShowLoading(false);
      setShowToast(true);
    } else {
      try {
        setShowLoading(true);
        const search = await api.get(`/products/search/${key}?page=1`);
        if (search.data.error) {
          setMessage(search.data.error);
          setShowLoading(false);
          setShowToast(true);
        } else {
          setProducts(search.data.search);
          setTotal(search.data.total);
          setPage(2);
          setLastPage(search.data.last_page);
          setShowLoading(false);
        }
      } catch (err) {
        setMessage('Erro - Puxe para Atualizar');
        setShowLoading(false);
        setShowToast(true);
      }
    }
  }, []);

  useEffect(() => {
    if (Capacitor.isNative) {
      Plugins.App.addListener('backButton', (e) => {
        setShowModal(false);
        setShowToast(false);
      });
    }
  }, []);

  useEffect(() => {
    getSearch(match.params.search);
  }, [getSearch, match.params.search]);

  /**
   * Close modal after adding item to cart
   */
  useEffect(() => {
    setTimeout(() => {
      setShowModal(false);
    }, 2100);
  }, [cart]);

  const pagination = async () => {
    if (products.length < total && page <= lastPage) {
      try {
        const pages = await api.get(`/products/search/${keyword}?page=${page}`);
        if (pages.data.error) {
          setMessage(pages.data.error);
          setShowToast(true);
        } else {
          setProducts(products.concat(pages.data.search));
          setPage(page + 1);
        }
      } catch (err) {
        setMessage('Erro - Puxe para Atualizar');
        setShowToast(true);
      }
    }
  };

  const doRefresh = (event) => {
    getSearch();
    event.detail.complete();
  };

  const doSearch = (event) => {
    if (event.key === 'Enter') {
      getSearch(keyword);
    }
  };

  const handleModalProduct = (product) => {
    setItem(product);
    setShowModal(true);
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color='custom'>
          <IonSearchbar
            color='custom'
            value={keyword}
            onIonChange={(e) => setKeyword(e.detail.value)}
            onKeyDown={doSearch}
            showCancelButton='focus'
            placeholder='Pesquisar'
          />
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonRefresher slot='fixed' onIonRefresh={doRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        <InfiniteScroll
          dataLength={products.length}
          next={pagination()}
          hasMore={true}
        >
          <IonGrid>
            <IonRow>
              {products.map((product, index) => (
                <IonCol
                  onClick={() => handleModalProduct(product)}
                  key={index}
                  size='12'
                  size-sm='6'
                  size-md='4'
                >
                  {Product(product, index)}
                </IonCol>
              ))}
            </IonRow>
          </IonGrid>
        </InfiniteScroll>
        <IonModal isOpen={showModal}>
          <IonHeader>
            <IonToolbar color='light'>
              <IonTitle>{item.name}</IonTitle>
              <IonIcon
                size='large'
                slot='end'
                icon={closeSharp}
                onClick={() => setShowModal(false)}
              />
            </IonToolbar>
          </IonHeader>
          <ProductModal item={item} />
        </IonModal>
      </IonContent>
      <IonLoading
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        message={'Aguarde...'}
      />
      <IonToast
        mode='ios'
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={message}
        duration={2000}
        color='warning'
        position='top'
      />
    </IonPage>
  );
}
