import React from 'react';
import {
  IonGrid,
  IonRow,
  IonCol,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardContent,
  IonLabel,
  IonChip,
} from '@ionic/react';

import { formatPrice } from '../util/format';
import { imgURL } from '../services/api';
import noImage from '../theme/images/no-image.png';

export default function Product(product) {
  return (
    <IonCard className='product-card-size'>
      <IonGrid>
        <IonRow>
          <IonCol size='8'>
            <IonCardHeader>
              <IonLabel>{product.name}</IonLabel>
            </IonCardHeader>
            <IonCardSubtitle>
              <IonChip outline>
                A partir de {formatPrice(parseFloat(product.price))}
              </IonChip>
            </IonCardSubtitle>
            <IonCardContent>
              <small>{product.description}</small>
            </IonCardContent>
          </IonCol>
          <IonCol size='4'>
            {product.image !== null ? (
              <img
                className='img-radious'
                src={`${imgURL}storage/images/${product.image}`}
                alt={product.name}
              />
            ) : (
              <img alt={product.name} src={noImage} />
            )}
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonCard>
  );
}
