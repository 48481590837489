import { combineReducers } from 'redux';

import AddressReducer from './AddressReducer';
import CartReducer from './CartReducer';
import ProductReducer from './ProductReducer';

export default combineReducers({
  address: AddressReducer,
  cart: CartReducer,
  product: ProductReducer,
});
