import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardTitle,
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonRow,
  IonText,
  IonTitle,
  IonToast,
} from '@ionic/react';
import { imgURL } from '../services/api';
import { formatPrice } from '../util/format';
import noImage from '../theme/images/no-image.png';

import { addOutline, cartOutline, removeOutline } from 'ionicons/icons';

export default function Modal({ item, edit }) {
  const dispatch = useDispatch();
  const product = useSelector((state) => state.product);
  const [showToast, setShowToast] = useState(false);
  const [message, setMessage] = useState('');

  useEffect(() => {
    item.total = item.price * item.quantity;
    item.total_extra = item.total_extra ?? 0;
    dispatch({ type: '@product/DATA', payload: item });
  }, [dispatch, item]);

  const addToCart = () => {
    if (edit) {
      dispatch({ type: '@cart/ALTER', payload: product });
      setMessage('Produto Alterado');
    } else {
      dispatch({ type: '@cart/ADD', payload: product });
      setMessage('Produto adicionado ao Carrinho');
    }
    setShowToast(true);
  };

  return (
    <>
      <IonContent>
        <IonCard>
          {item.image !== null ? (
            <img
              className='img-radious'
              src={`${imgURL}storage/images/${product.image}`}
              alt={product.name}
            />
          ) : (
            <img alt={product.name} src={noImage} />
          )}
          <IonCardTitle>
            <IonTitle>{product.name}</IonTitle>
          </IonCardTitle>
          <IonCardContent>
            <div>
              <IonText>Descrição:{product.description}</IonText>
            </div>
            {typeof product.extra === 'undefined' && (
              <>
                <IonButton
                  onClick={() =>
                    dispatch({ type: '@product/DECREMENT_PRODUCT' })
                  }
                  size='small'
                  color='medium'
                  shape='round'
                  outline
                >
                  <IonIcon icon={removeOutline} />
                </IonButton>

                <IonButton size='small' fill='flat'>
                  <IonTitle size='small'>{product.quantity}</IonTitle>
                </IonButton>

                <IonButton
                  onClick={() =>
                    dispatch({ type: '@product/INCREMENT_PRODUCT' })
                  }
                  size='small'
                  color='medium'
                  shape='round'
                  outline
                >
                  <IonIcon icon={addOutline} />
                </IonButton>
              </>
            )}
          </IonCardContent>
        </IonCard>
        {typeof product.extra !== 'undefined' && (
          <IonCard>
            <IonCardTitle>Itens Extras</IonCardTitle>
            <IonGrid>
              {product.extra.map((extra, index) => (
                <IonRow key={index}>
                  <IonCol>
                    <IonLabel>{extra.name}</IonLabel>
                  </IonCol>
                  <IonCol>{`${formatPrice(parseFloat(extra.price))}`}</IonCol>
                  <IonCol
                    className='product-col-align-8px'
                    size='2'
                    onClick={() =>
                      dispatch({
                        type: '@product/DECREMENT_EXTRA',
                        payload: { extra: extra.id },
                      })
                    }
                  >
                    <IonIcon size='large' icon={removeOutline} />
                  </IonCol>
                  <IonCol size='1' className='product-col-align-3px'>
                    <IonLabel>
                      <big>{extra.quantity}</big>
                    </IonLabel>
                  </IonCol>
                  <IonCol
                    className='product-col-align-8px'
                    size='2'
                    onClick={() =>
                      dispatch({
                        type: '@product/INCREMENT_EXTRA',
                        payload: { extra: extra.id },
                      })
                    }
                  >
                    <IonIcon size='large' icon={addOutline} />
                  </IonCol>
                </IonRow>
              ))}
            </IonGrid>
          </IonCard>
        )}
      </IonContent>
      <IonFooter className='ion-no-border footer-border-modal'>
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonItem lines='none'>
                <IonLabel>
                  <strong>TOTAL: </strong>
                  {formatPrice(parseFloat(product.total + product.total_extra))}
                </IonLabel>
                <IonButton
                  size='small'
                  color='success'
                  shape='round'
                  fill='outline'
                  onClick={() => addToCart()}
                >
                  <IonLabel>{edit ? 'Alterar' : 'Comprar'}</IonLabel>
                  <IonIcon icon={cartOutline} />
                </IonButton>
              </IonItem>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonFooter>
      <IonToast
        mode='ios'
        animated
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={message}
        duration={1000}
        color='success'
        position='top'
      />
    </>
  );
}
