import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonRow,
  IonGrid,
  IonCol,
  IonIcon,
  IonFooter,
  IonButton,
  IonModal,
  IonLabel,
  IonNote,
  IonItem,
  IonAlert,
  IonToast,
  IonCard,
  IonItemDivider,
  IonChip,
  IonButtons,
  IonText,
  IonImg,
} from '@ionic/react';

import Modal from '../../components/Modal';
import { formatPrice } from '../../util/format';
import emptyCart from '../../theme/images/cart.gif';
import noImage from '../../theme/images/no-image.png';
import ProductModal from '../../components/ProductModal';

import {
  closeOutline,
  trashOutline,
  closeSharp,
  optionsOutline,
} from 'ionicons/icons';
import { Capacitor, Plugins } from '@capacitor/core';
import { imgURL } from '../../services/api';

function Cart() {
  const cart = useSelector((state) => state.cart);
  const [showModal, setShowModal] = useState(false);
  const [showProductModal, setShowProductModal] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [data, setData] = useState('');
  const [showAlertClear, setShowAlertClear] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [rubish, setRubish] = useState('');
  const [total, setTotal] = useState('');
  const dispatch = useDispatch();

  //Back button Listener
  useEffect(() => {
    if (Capacitor.isNative) {
      Plugins.App.addListener('backButton', (e) => {
        setShowModal(false);
        setShowProductModal(false);
      });
    }
  }, []);

  useEffect(() => {
    if (rubish !== '') {
      setShowAlert(true);
    }
  }, [rubish]);

  useEffect(() => {
    setTotal(
      cart.reduce(
        (previousPrice, element) =>
          previousPrice +
          element.price * element.quantity +
          element.total_extra,
        0
      )
    );
  }, [cart]);

  /**
   * Close modal after adltering item
   */
  useEffect(() => {
    setTimeout(() => {
      setShowProductModal(false);
    }, 2100);
  }, [cart]);

  const deleteFromCart = () => {
    dispatch({
      type: '@cart/DELETE',
      payload: {
        product: rubish[0],
      },
    });
  };

  const clearCart = () => {
    dispatch({
      type: '@cart/CLEAR',
    });
  };

  const handleModalProduct = (product) => {
    setData(product);
    setShowProductModal(true);
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color='light'>
          <IonButtons>
            <IonTitle>CARRINHO</IonTitle>
            <IonChip onClick={() => setShowAlertClear(true)}>
              <IonLabel>Limpar</IonLabel>
              <IonIcon icon={trashOutline} />
            </IonChip>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {total === 0 && <IonImg src={emptyCart} />}
        <IonCard>
          {cart.map((item, index) => (
            <IonGrid key={index}>
              <IonRow>
                <IonCol size='3'>
                  {item.image !== null ? (
                    <img
                      className='img-radious-cart'
                      src={`${imgURL}storage/images/${item.image}`}
                      alt={item.name}
                    />
                  ) : (
                    <img alt={item.name} src={noImage} />
                  )}
                </IonCol>
                <IonCol>
                  <IonLabel>
                    <strong>{item.name}</strong>
                  </IonLabel>
                  {typeof item.extra !== 'undefined' &&
                    item.extra.map(
                      (extra) =>
                        extra.quantity > 0 && (
                          <IonGrid key={extra.id}>
                            <IonRow>
                              <IonCol>
                                <IonText>
                                  Extra: {extra.quantity} x {extra.name}
                                </IonText>
                              </IonCol>
                            </IonRow>
                          </IonGrid>
                        )
                    )}
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>Quantidade</IonCol>
                <IonCol>Valor</IonCol>
                <IonCol>Excluir</IonCol>
                <IonCol>Editar</IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonCol>
                    <IonLabel>{item.quantity}</IonLabel>
                  </IonCol>
                </IonCol>
                <IonCol>{formatPrice(item.total + item.total_extra)}</IonCol>
                <IonCol onClick={() => setRubish([item])}>
                  <IonIcon icon={trashOutline} />
                </IonCol>
                <IonCol onClick={() => handleModalProduct(item)}>
                  <IonIcon icon={optionsOutline} />
                </IonCol>
              </IonRow>
              <IonItemDivider />
            </IonGrid>
          ))}
        </IonCard>

        <IonModal isOpen={showProductModal}>
          <IonHeader>
            <IonToolbar color='light'>
              <IonTitle>{data.name}</IonTitle>
              <IonIcon
                size='large'
                slot='end'
                icon={closeSharp}
                onClick={() => setShowProductModal(false)}
              />
            </IonToolbar>
          </IonHeader>
          <ProductModal item={data} edit={true} />
        </IonModal>
        <IonModal isOpen={showModal}>
          <IonHeader>
            <IonToolbar color='light'>
              <IonTitle>Finalizar Pedido</IonTitle>
              <IonIcon
                size='large'
                slot='end'
                icon={closeOutline}
                onClick={() => setShowModal(false)}
              />
            </IonToolbar>
          </IonHeader>
          <Modal cart={cart} total={total} />
        </IonModal>
      </IonContent>
      <IonFooter className='ion-no-border footer-border-modal'>
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonItem lines='none'>
                <IonLabel>
                  <strong>TOTAL:</strong>
                </IonLabel>
                <IonNote slot='end'>
                  <strong>{formatPrice(total)}</strong>
                </IonNote>
              </IonItem>
            </IonCol>
          </IonRow>
        </IonGrid>
        {total > 0 && (
          <IonButton
            onClick={() => setShowModal(true)}
            color='success'
            expand='block'
          >
            Finalizar Compra
          </IonButton>
        )}
      </IonFooter>
      <IonAlert
        isOpen={showAlert}
        header={'Excluir Produto'}
        onDidDismiss={() => setShowAlert(false)}
        message={`${rubish[0] ? rubish[0].name : ''}`}
        animated={true}
        buttons={[
          {
            text: 'Cancelar',
            handler: () => {
              setRubish([]);
            },
          },
          {
            text: 'Excluir',
            handler: () => {
              deleteFromCart();
              setRubish([]);
            },
          },
        ]}
      />
      <IonAlert
        isOpen={showAlertClear}
        header={'Esvaziar Carrinho?'}
        onDidDismiss={() => setShowAlertClear(false)}
        animated={true}
        buttons={[
          {
            text: 'Cancelar',
          },
          {
            text: 'Limpar',
            handler: () => {
              clearCart();
            },
          },
        ]}
      />
      <IonToast
        mode='ios'
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message='Quantidade de METADES insuficiente.'
        duration={2000}
        color='warning'
        position='top'
      />
    </IonPage>
  );
}

export default Cart;
