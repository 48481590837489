import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

import {
  IonApp,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonBadge,
} from '@ionic/react';

import { IonReactRouter } from '@ionic/react-router';
import { bookOutline, cartOutline, homeOutline } from 'ionicons/icons';

import About from './pages/about';
import Cart from './pages/cart';
import Menu from './pages/menu';
import Search from './pages/search';

export default function App() {
  const cart = useSelector((state) => state.cart);

  return (
    <IonApp>
      <IonReactRouter>
        <IonTabs>
          <IonRouterOutlet>
            <Route path='/' component={Menu} exact={true} />
            <Route path='/cart' component={Cart} exact={true} />
            <Route path='/about' component={About} exact={true} />
            <Route path='/search/:search' component={Search} exact={true} />
            <Route render={() => <Redirect to='/' />} exact={true} />
          </IonRouterOutlet>
          <IonTabBar slot='bottom'>
            <IonTabButton tab='menu' href='/'>
              <IonIcon size='large' icon={bookOutline} />
              <IonLabel>Menu</IonLabel>
            </IonTabButton>
            <IonTabButton tab='cart' href='/cart'>
              <IonIcon size='large' icon={cartOutline} />
              {cart.length > 0 ? (
                <IonBadge color='success'>{cart.length}</IonBadge>
              ) : (
                ''
              )}
              <IonLabel>Carrinho</IonLabel>
            </IonTabButton>
            <IonTabButton tab='about' href='/about'>
              <IonIcon size='large' icon={homeOutline} />
              <IonLabel>Sobre</IonLabel>
            </IonTabButton>
          </IonTabBar>
        </IonTabs>
      </IonReactRouter>
    </IonApp>
  );
}
