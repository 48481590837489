import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  IonContent,
  IonHeader,
  IonPage,
  IonImg,
  IonRefresher,
  IonRefresherContent,
  IonToast,
  IonTitle,
  IonSegment,
  IonSegmentButton,
  IonLabel,
  IonToolbar,
  IonIcon,
  IonButton,
  IonButtons,
  IonGrid,
  IonRow,
  IonCol,
  IonModal,
  IonPopover,
  IonSearchbar,
  IonCard,
} from '@ionic/react';

import InfiniteScroll from 'react-infinite-scroll-component';

import { api } from '../../services/api';

import CategoryShimmer from '../../components/CategoryShimmer';
import SlideShimmer from '../../components/SlideShimmer';
import ProductShimmer from '../../components/ProductShimmer';
import HomeSlides from '../../components/HomeSlides';
import ProductModal from '../../components/ProductModal';

import noSale from '../../theme/images/no-sale.gif';

import { closeSharp, search, starOutline } from 'ionicons/icons';
import Product from '../../components/Product';
import { Capacitor, Plugins } from '@capacitor/core';

export default function Menu() {
  const history = useHistory();
  const cart = useSelector((state) => state.cart);

  const [showToast, setShowToast] = useState(false);
  const [message, setMessage] = useState('');
  const [variables, setVariables] = useState({
    segment: 'Destaques',
    id_category: 0,
  });
  const [categories, setCategories] = useState([]);
  const [images, setImages] = useState([]);
  const [categoriesLoading, setCategoriesLoading] = useState(true);
  const [slidesLoading, setSlidesLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [keyword, setKeyword] = useState('');
  const [showPopover, setShowPopover] = useState(false);
  const [products, setProducts] = useState([]);
  const [productsLoading, setProductsLoading] = useState(true);
  const [page, setPage] = useState();
  const [total, setTotal] = useState();
  const [lastPage, setLastPage] = useState('');
  const [item, setItem] = useState('');

  useEffect(() => {
    setCategoriesLoading(true);
    setSlidesLoading(true);
    setProductsLoading(true);

    getSale();
    getCategories();
    getSlides();
  }, []);

  /**
   * Close modal after adding item to cart
   */
  useEffect(() => {
    setTimeout(() => {
      setShowModal(false);
    }, 2100);
  }, [cart]);

  useEffect(() => {
    if (Capacitor.isNative) {
      Plugins.App.addListener('backButton', (e) => {
        setShowModal(false);
        setShowPopover(false);
      });
    }
  }, []);

  const getSlides = async () => {
    try {
      const slides = await api.get('/images');
      if (slides.data.error) {
        setMessage(slides.data.error);
        setShowToast(true);
      } else {
        setImages(slides.data.slides);
        setSlidesLoading(false);
      }
    } catch (error) {
      setMessage('Erro - Puxe para Atualizar');
      setShowToast(true);
    }
  };

  const getCategories = async () => {
    try {
      const category = await api.get('/category');
      if (category.data.error) {
        setMessage(category.data.error);
        setShowToast(true);
      } else {
        setCategories(category.data.categories);
        setCategoriesLoading(false);
      }
    } catch (error) {
      setMessage('Erro - Puxe para Atualizar');
      setShowToast(true);
    }
  };

  const getSale = async () => {
    try {
      const sale = await api.get(`/products/sale?page=1`);
      if (sale.data.error) {
        setMessage(sale.data.error);
        setShowToast(true);
      } else {
        setProducts(sale.data.products);
        setTotal(sale.data.total);
        setPage(2);
        setLastPage(sale.data.last_page);
        setProductsLoading(false);
      }
    } catch (err) {
      setMessage('Erro - Puxe para Atualizar');
      setShowToast(true);
    }
  };

  const getCategoryData = async (id_category, category) => {
    setProductsLoading(true);
    setVariables({ segment: category, id_category });
    try {
      let route = '';
      if (parseInt(id_category) === 0) {
        route = `/products/sale?page=1`;
      } else {
        route = `/products/category/${id_category}?page=1`;
      }
      const product = await api.get(route);
      if (product.data.error) {
        setMessage(product.data.error);
        setShowToast(true);
      } else {
        setProducts([]);
        setProducts(product.data.products);
        setTotal(product.data.total);
        setPage(2);
        setLastPage(product.data.last_page);
        setProductsLoading(false);
      }
    } catch (err) {
      setMessage('Erro - Puxe para Atualizar');
      setProductsLoading(false);
      setShowToast(true);
    }
  };

  const doRefresh = (event) => {
    setCategoriesLoading(true);
    setSlidesLoading(true);
    setProductsLoading(true);

    getCategoryData(variables.id_category, variables.segment);
    getCategories();
    getSlides();

    event.detail.complete();
  };

  const pagination = async () => {
    if (products.length < total && page <= lastPage) {
      let route = '';
      if (parseInt(variables.id_category) === 0) {
        route = `/products/sale?page=${page}`;
      } else {
        route = `/products/category/${variables.id_category}?page=${page}`;
      }
      try {
        const pages = await api.get(route);
        if (pages.data.error) {
          setMessage(pages.data.error);
          setShowToast(true);
        } else {
          setProducts(products.concat(pages.data.products));
          setPage(page + 1);
        }
      } catch (err) {
        setMessage('Erro - Puxe para Atualizar');
        setShowToast(true);
      }
    }
  };

  const handleModalProduct = (product) => {
    setItem(product);
    setShowModal(true);
  };

  const doSearch = (event) => {
    if (event.key === 'Enter') {
      history.push(`/search/${keyword}`);
      setKeyword();
      setShowPopover(false);
    }
  };

  return (
    <IonPage>
      <IonHeader>
        {!categoriesLoading ? (
          categories.length === 0 ? (
            <>
              <IonImg src={noSale} />
              <IonTitle>Em breve!</IonTitle>
            </>
          ) : (
            <>
              <IonToolbar color='custom'>
                <IonButtons>
                  <IonButton />
                  <IonTitle className='text-align-center'>MENU</IonTitle>
                  <IonButton onClick={() => setShowPopover(true)}>
                    <IonIcon slot='icon-only' icon={search} />
                  </IonButton>
                </IonButtons>
              </IonToolbar>
              <IonSegment scrollable color='dark' value={variables.segment}>
                {categories.map((category) => {
                  const ref = React.createRef();

                  const handleClick = (id, category) => {
                    ref.current.nextSibling !== null
                      ? ref.current.nextSibling.scrollIntoView({
                          behaviour: 'smooth',
                          block: 'start',
                        })
                      : ref.current.scrollIntoView({
                          behaviour: 'smooth',
                          block: 'start',
                        });
                    getCategoryData(id, category);
                  };
                  return (
                    <IonSegmentButton
                      key={category.id}
                      ref={ref}
                      value={category.category}
                      onClick={() =>
                        handleClick(category.id, category.category)
                      }
                    >
                      <IonLabel>{category.category}</IonLabel>
                    </IonSegmentButton>
                  );
                })}
              </IonSegment>
            </>
          )
        ) : (
          <CategoryShimmer />
        )}
      </IonHeader>
      <IonContent>
        {!slidesLoading ? <HomeSlides images={images} /> : <SlideShimmer />}
        <IonRefresher slot='fixed' onIonRefresh={doRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        {!productsLoading ? (
          products.length === 0 ? (
            <>
              <IonImg src={noSale} />
              <IonTitle>Em breve!</IonTitle>
            </>
          ) : (
            <InfiniteScroll
              dataLength={products.length}
              next={pagination()}
              hasMore={true}
            >
              <IonGrid>
                <IonRow>
                  <IonCol className='text-align-center'>
                    <IonLabel>~</IonLabel>
                    <IonIcon icon={starOutline} />
                    <IonLabel>~</IonLabel>
                  </IonCol>
                </IonRow>
                <IonRow>
                  {products.map((product, index) => (
                    <IonCol
                      onClick={() => handleModalProduct(product)}
                      key={index}
                      size='12'
                      size-sm='6'
                      size-md='4'
                    >
                      {Product(product, index)}
                    </IonCol>
                  ))}
                </IonRow>
              </IonGrid>
            </InfiniteScroll>
          )
        ) : (
          <ProductShimmer />
        )}
        <IonModal isOpen={showModal}>
          <IonHeader>
            <IonToolbar color='light'>
              <IonTitle>{item.name}</IonTitle>
              <IonIcon
                size='large'
                slot='end'
                icon={closeSharp}
                onClick={() => setShowModal(false)}
              />
            </IonToolbar>
          </IonHeader>
          <ProductModal item={item} edit={false} />
        </IonModal>
      </IonContent>
      <IonPopover
        isOpen={showPopover}
        onDidDismiss={(e) => setShowPopover(false)}
      >
        <IonCard>
          <IonTitle>Pesquisar</IonTitle>
          <IonSearchbar
            color='custom'
            value={keyword}
            onIonChange={(e) => setKeyword(e.detail.value)}
            onKeyDown={doSearch}
            showCancelButton='focus'
            placeholder='Pesquisar'
          />
        </IonCard>
      </IonPopover>
      <IonToast
        mode='ios'
        animated
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={message}
        duration={2000}
        color='warning'
        position='top'
      />
    </IonPage>
  );
}
