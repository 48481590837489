import React, { useState, useEffect } from 'react';
import { isPlatform } from '@ionic/react';

import { IonCard, IonImg, IonSlide, IonSlides } from '@ionic/react';

import { slideOptionsMobile, slideOptionsPc } from '../services/slideOptions';
import { imgURL } from '../services/api';

export default function HomeSlides({ images }) {
  const [slideOption, setSlideOption] = useState();

  useEffect(() => {
    if (isPlatform('desktop')) {
      setSlideOption(slideOptionsPc);
    } else {
      setSlideOption(slideOptionsMobile);
    }
  }, []);

  return (
    <>
      <IonSlides options={slideOption}>
        {images.slide_1 && (
          <IonSlide>
            <IonCard>
              <IonImg src={`${imgURL}storage/images/${images.slide_1}`} />
            </IonCard>
          </IonSlide>
        )}
        {images.slide_2 && (
          <IonSlide>
            <IonCard>
              <IonImg src={`${imgURL}storage/images/${images.slide_2}`} />
            </IonCard>
          </IonSlide>
        )}
        {images.slide_3 && (
          <IonSlide>
            <IonCard>
              <IonImg src={`${imgURL}storage/images/${images.slide_3}`} />
            </IonCard>
          </IonSlide>
        )}
        {images.slide_4 && (
          <IonSlide>
            <IonCard>
              <IonImg src={`${imgURL}storage/images/${images.slide_4}`} />
            </IonCard>
          </IonSlide>
        )}
      </IonSlides>
    </>
  );
}
