export default function CartReducer(state = [], action) {
  switch (action.type) {
    case '@cart/ADD':
      return [...state, action.payload];
    case '@cart/DELETE':
      for (let index = 0; index < state.length; index++) {
        if (state[index].id === action.payload.product.id) {
          state.splice(index, 1);
        }
      }
      return [...state];
    case '@cart/ALTER':
      for (let index = 0; index < state.length; index++) {
        if (state[index].id === action.payload.id) {
          state.splice(index, 1);
        }
      }
      return [...state, action.payload];
    case '@cart/CLEAR':
      state = [];
      return state;
    case '@cart/DECREASE_AMOUNT':
      for (let index = 0; index < state.length; index++) {
        if (
          state[index].id === action.payload.id &&
          state[index].quantity > 1
        ) {
          state[index].quantity--;
          return [...state];
        }
      }
      return state;
    case '@cart/INCREASE_AMOUNT':
      for (let index = 0; index < state.length; index++) {
        if (
          state[index].id === action.payload.id &&
          state[index].quantity >= 1
        ) {
          state[index].quantity++;
          return [...state];
        }
      }
      return state;
    default:
      return state;
  }
}
