import axios from 'axios';

export const idCompany = 1;

let enviroment = 'productions';

let imgRoute = '';
let baseRoute = '';

if (enviroment === 'productions') {
  imgRoute = 'https://web7app.com/';
  baseRoute = `https://web7app.com/api/v1/company/${idCompany}`;
} else {
  imgRoute = 'https://dashboardweb7delivery.laragon/';
  baseRoute = `https://dashboardweb7delivery.laragon/api/v1/company/${idCompany}`;
}

export const imgURL = imgRoute;
export const baseURL = baseRoute;

export const api = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
});
