import React from 'react';

export default function AboutShimmer() {
  return (
    <>
      <div className='AboutShimmer animate'></div>
      <div className='AboutShimmer animate'></div>
    </>
  );
}
